import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { AuthenticationService } from 'app/auth/authentication.service';

import { environment } from 'app/environment/environment';

import { EngineeringRestriction } from 'app/main/model/engineering/engineering-restriction.model';
import { EngineeringActivity } from 'app/main/model/engineering/engineering-activity.model';
import { EngineeringRestrictionStatus } from 'app/main/model/engineering/engineering-restriction-status.model';
import { EngineeringRestrictionEngineeringActivity } from 'app/main/model/engineering/engineering-restriction-engineering-activity.model';
import { User } from 'app/main/configurations/user/user.model';

@Injectable()
export class EngineeringRestrictionEngineeringActivityService
{
    apiUrl = environment.apiUrl + "/engineering/restriction/engineering/activity";
    
    engineeringRestrictionEngineeringActivity: EngineeringRestrictionEngineeringActivity;
    onEngineeringRestrictionChanged: BehaviorSubject<any>;

    engineeringRestrictionEngineeringActivitys: EngineeringRestrictionEngineeringActivity[];
    onEngineeringRestrictionsChanged: BehaviorSubject<any>;

    constructor(
        private _httpClient: HttpClient,
        private _authService: AuthenticationService
    )
    {
        this.onEngineeringRestrictionChanged = new BehaviorSubject({});
        this.onEngineeringRestrictionsChanged = new BehaviorSubject([]);
    }

    create(engineeringRestriction: EngineeringRestriction, engineeringActivity: EngineeringActivity, startDate: Date, responsible: User): Promise<any> {
        let request = {
            engineeringRestriction: engineeringRestriction,
            engineeringActivity: engineeringActivity,
            startDate: startDate,
            responsible: responsible
        };
        
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + '/create', {...request}).subscribe((response: any) => {
                resolve(resolve);
            });
        });
    }

    update(engineeringRestrictionEngineeringActivity: EngineeringRestrictionEngineeringActivity, engineeringRestrictionStatus: EngineeringRestrictionStatus): Promise<any[]> {
        let request = {
            engineeringRestrictionEngineeringActivity: engineeringRestrictionEngineeringActivity,
            engineeringRestrictionStatus: engineeringRestrictionStatus
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/update", {...request}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }

    find(engineeringRestrictionEngineeringActivityId): Promise<EngineeringRestrictionEngineeringActivity> {
        return new Promise((resolve) => {
            this._httpClient.get(this.apiUrl + '/find/' + engineeringRestrictionEngineeringActivityId).subscribe((response: EngineeringRestrictionEngineeringActivity) => {
                this.engineeringRestrictionEngineeringActivity = response;
                this.onEngineeringRestrictionChanged.next(this.engineeringRestrictionEngineeringActivity);
                resolve(this.engineeringRestrictionEngineeringActivity);
            });
        });
    }

    list(engineeringRestrictionStatus: EngineeringRestrictionStatus): Promise<any[]> {
        let development = this._authService.currentDevelopmentValue;
        
        let request = {
            development: development,
            engineeringRestrictionStatus: engineeringRestrictionStatus
        };
        
        return new Promise((resolve) => {
            this._httpClient.post(this.apiUrl + '/list', {...request}).subscribe((response: EngineeringRestrictionEngineeringActivity[]) => {
                this.engineeringRestrictionEngineeringActivitys = response;
                this.onEngineeringRestrictionsChanged.next(this.engineeringRestrictionEngineeringActivitys);

                resolve(this.engineeringRestrictionEngineeringActivitys);
            });
        });
    }

    delete(engineeringRestrictionEngineeringActivity: EngineeringRestrictionEngineeringActivity): Promise<any> {
        let request = {
            engineeringRestrictionEngineeringActivity: engineeringRestrictionEngineeringActivity
        };

        return new Promise((resolve, reject) => {
            this._httpClient.put(this.apiUrl + "/delete", {...request}).subscribe((response: any) => {
                resolve(response);
            }, reject);
        });
    }
}